import {useState} from "react";
import Project from "./Projects"
import movie1 from "../assets/MovieBrain/movie1.PNG"
import movie3 from "../assets/MovieBrain/movier3.PNG"
import movie4 from "../assets/MovieBrain/movie4.png"
import movie5 from "../assets/MovieBrain/movie5.PNG"




function MovieBrain(){
    const [quitViewMore, setQuitViewMore] = useState(null);

    const handlequitViewMoreClick = (quit) => {
        setQuitViewMore(quit);
    };

    switch (quitViewMore) {
        case "quit":
            return <Project/>
        default:
            return (

                <article
                    className="backdrop-blur-md rounded-xl w-9/12 shadow-2xl max-h-screen overflow-hidden overflow-y-auto">
                    <div className="m-2">
                        <svg onClick={() => handlequitViewMoreClick("quit")} xmlns="http://www.w3.org/2000/svg"
                             className="w-3 cursor-pointer absolute" viewBox="0 0 320 512">
                            <path fill="#b794f4"
                                  d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                        </svg>
                        <h1 className="uppercase tracking-wide flex items-center pb-8 justify-center text-sm text-purple-400 font-bold">Movie Brain</h1>
                    </div>
                    <div className="md:flex p-4">
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-96 md:w-60" src={movie1}
                                 alt="Nespoli"/>
                        </div>
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">I worked on MovieBrain for 1 month alongside my internship. This React Native project utilizes the themoviedb API as well as an API I developed in Node.js, which manages user accounts. The API was deployed on Microsoft Azure. The themoviedb API is used to retrieve all movies and series, enabling filtering by release date, popularity, and genre. This project allowed me to deepen my knowledge of React Native and gain insights into the functioning of Microsoft Azure. </p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">To enable users to manage their accounts, I implemented a profile view where they can view and modify their information, manage account security, view their favorite movies and series, as well as an album of photos they have taken. Additionally, users have the option to log out.</p>
                        </div>
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-96 md:w-60" src={movie5}
                                 alt="Nespoli"/>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-96 md:w-60" src={movie3}
                                 alt="Nespoli"/>
                        </div>
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">I also worked on the list of movies and series displayed as a list, which shows the information retrieved from themoviedb. Users can manually search for the desired movie or series, or they can browse through various categories such as 'Now Playing,' 'Popular,' or 'Upcoming'.</p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">To conclude, I created the movieDetails view which allows the user to access more details about the movie they clicked on. It includes a movie poster, title, synopsis, director, release date, and the option to add the movie to their favorites.</p>
                        </div>
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-96 md:w-60" src={movie4}
                                 alt="Nespoli"/>
                        </div>
                    </div>
                </article>
            )
    }
}
export default MovieBrain
