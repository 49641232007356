import {useState} from "react";
import Project from "./Projects"
import nespo1 from "../assets/Nespoli/nespo1.png"
import nespo2 from "../assets/Nespoli/nespo2.png"
import nespo3 from "../assets/Nespoli/nespo3.png"


function Nespoli(){
    const [quitViewMore, setQuitViewMore] = useState(null);

    const handlequitViewMoreClick = (quit) => {
        setQuitViewMore(quit);
    };

    switch (quitViewMore) {
        case "quit":
            return <Project/>
        default:
            return (

                <article
                    className="backdrop-blur-md rounded-xl w-9/12 shadow-2xl max-h-screen overflow-hidden overflow-y-auto">
                    <div className="m-2">
                        <svg onClick={() => handlequitViewMoreClick("quit")} xmlns="http://www.w3.org/2000/svg"
                             className="w-3 cursor-pointer absolute" viewBox="0 0 320 512">
                            <path fill="#b794f4"
                                  d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                        </svg>
                        <h1 className="uppercase tracking-wide flex items-center pb-8 justify-center text-sm text-purple-400 font-bold">Nespoli</h1>
                    </div>
                    <div className="md:flex p-4">
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-60 md:w-96" src={nespo1}
                                 alt="Nespoli"/>
                        </div>
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">For a month, I worked with Nespoli France to develop a
                                stock management website. I collaborated with a colleague who handled the front-end,
                                while I focused on the back-end using PHP. It was an enriching experience as it marked
                                my first professional experience as a web developer. </p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">My responsibilities included user creation, login
                                functionality, the development of an admin panel, and implementing features such as
                                adding articles directly to the database.</p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-60 md:w-96" src={nespo2}
                                 alt="Nespoli"/>
                        </div>
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">I also worked on creating alerts for low-stock items,
                                triggering the generation of a PDF for ordering purposes. The website included search
                                functionality by article and various filters.</p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">This experience allowed me to gain valuable insights into
                                the practical aspects of web development and honed my skills in both front-end and
                                back-end technologies.</p>
                        </div>
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="md:h-60 md:w-96" src={nespo3}
                                 alt="Nespoli"/>
                        </div>
                    </div>
                </article>
            )
    }
}
export default Nespoli
