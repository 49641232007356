import NftHome from "../assets/Virgo/Nft home.png"
import SendPane from "../assets/Virgo/SendPaneConfirm.png"
import TransacPane from "../assets/Virgo/TransacPane.png"
import VirgoMenu from "../assets/Virgo/VirgoWallet.png"
import Project from "./Projects"
import {useState} from "react";




function Virgo(){
    const [quitViewMore, setQuitViewMore] = useState(null);

    const handlequitViewMoreClick = (quit) => {
        setQuitViewMore(quit);
    };

    switch (quitViewMore) {
        case "quit":
            return <Project/>
        default:
            return (

                <article
                    className="backdrop-blur-md rounded-xl w-9/12 shadow-2xl max-h-screen overflow-hidden overflow-y-auto">
                    <div className="m-2">
                        <svg onClick={() => handlequitViewMoreClick("quit")} xmlns="http://www.w3.org/2000/svg"
                             className="w-3 cursor-pointer absolute" viewBox="0 0 320 512">
                            <path fill="#b794f4"
                                  d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                        </svg>
                        <h1 className="uppercase tracking-wide flex items-center pb-8 justify-center text-sm text-purple-400 font-bold"> Virgo</h1>
                    </div>
                    <div className="md:flex p-4">
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="w-full object-cover md:h-full md:h-48 md:w-48 h-48"
                                 src={VirgoMenu} alt="Oppenheimer"/>
                        </div>
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">Virgo is a cryptocurrency startup based in Lille, with
                                its own coin called VGO. They also develop their own wallet. During my internship at
                                this startup, my tasks included redesigning the wallet interface. Additionally, I had to
                                add new functionalities to the wallet, such as integrating NFTs using the ERC-721
                                contract. </p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">This allowed me to retrieve all the information about the
                                NFTs owned by the user and display it within the wallet, all implemented in
                                JavaScript.</p>
                        </div>
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="w-full object-cover md:h-full md:h-48 md:w-48 h-48" src={NftHome}
                                 alt="Tweeter"/>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="w-full object-cover md:h-full md:h-48  h-48 w-full object-top"
                                 src={SendPane} alt="Tweeter"/>
                        </div>
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">In addition to the graphical redesign of the wallet and
                                the integration of NFTs, I actively contributed to enhancing its user experience. This
                                involved optimizing the wallet's performance and ensuring a seamless interaction for
                                users managing their VGO and NFT assets.</p>
                        </div>
                    </div>
                    <div className="md:flex p-4">
                        <div className="p-8">
                            <p className="mt-2 text-slate-400">Furthermore, I played a pivotal role in troubleshooting
                                and debugging, ensuring the wallet's robustness in handling diverse scenarios. The
                                dynamic environment of a cryptocurrency startup provided a unique learning experience,
                                allowing me to broaden my skills in JavaScript development, blockchain technology, and
                                user interface design. </p>
                        </div>
                        <div className="md:shrink-0 2xl:shrink-0">
                            <img className="w-full object-cover md:h-full md:h-48 md:w-48 h-48"
                                 src={TransacPane} alt="Tweeter"/>
                        </div>
                    </div>
                    <div  className=" flex items-center justify-center rounded-lg cursor-pointer ">
                        <svg onClick={() => document.location.href='https://github.com/virgoproject/VirgoWallet'}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30" className="h-8 w-8 rounded-xl hover:text-gray-800  hover:duration-300 hover:ease-linear focus:bg-white" fill="currentColor">
                            <path fillRule="evenodd" d="M15,3C8.373,3,3,8.373,3,15c0,5.623,3.872,10.328,9.092,11.63C12.036,26.468,12,26.28,12,26.047v-2.051 c-0.487,0-1.303,0-1.508,0c-0.821,0-1.551-0.353-1.905-1.009c-0.393-0.729-0.461-1.844-1.435-2.526 c-0.289-0.227-0.069-0.486,0.264-0.451c0.615,0.174,1.125,0.596,1.605,1.222c0.478,0.627,0.703,0.769,1.596,0.769 c0.433,0,1.081-0.025,1.691-0.121c0.328-0.833,0.895-1.6,1.588-1.962c-3.996-0.411-5.903-2.399-5.903-5.098 c0-1.162,0.495-2.286,1.336-3.233C9.053,10.647,8.706,8.73,9.435,8c1.798,0,2.885,1.166,3.146,1.481C13.477,9.174,14.461,9,15.495,9 c1.036,0,2.024,0.174,2.922,0.483C18.675,9.17,19.763,8,21.565,8c0.732,0.731,0.381,2.656,0.102,3.594 c0.836,0.945,1.328,2.066,1.328,3.226c0,2.697-1.904,4.684-5.894,5.097C18.199,20.49,19,22.1,19,23.313v2.734 c0,0.104-0.023,0.179-0.035,0.268C23.641,24.676,27,20.236,27,15C27,8.373,21.627,3,15,3z"></path>
                        </svg>
                    </div>
                </article>
            )
    }
}
export default Virgo
