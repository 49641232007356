import Picture from "../assets/picturewithoutbackground.png"

function Accueil(){
    return(

            <div className=" grid  max-h-screen backdrop-blur-md max-w-md flex items-center justify-center mx-auto rounded-xl shadow-2xl overflow-y-auto md:max-w-2xl">
                <div className="md:flex">
                    <div className="md:shrink-0 2xl:shrink-0">
                        <img className="h-60 w-full object-cover md:h-full md:w-60" src={Picture}
                             alt="Modern building architecture"/>
                    </div>
                    <div className="p-8">
                        <div className="uppercase tracking-wide text-sm text-purple-400	 font-bold">Hello</div>
                        <p className="mt-2 text-slate-400"> I'm Corentin Pomme, a full-stack developer with a knack for back-end solutions. Proficient in Node.js. Also for the front-end ReactJS and React Nativ. I love tackling complex challenges.
                            Beyond coding, I'm passionate about travel, exploring new cultures, and solving problems. When not coding, you'll find me enjoying friendly moments with friends over meals or games.
                            Let's connect and discuss our shared passions!</p>
                    </div>
                </div>
            </div>
    )
}
export default Accueil
