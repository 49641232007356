import './App.css';
import Navbar from "./Component/Navbar";
import {useState} from "react";
import Accueil from "./Component/Accueil";
import NetworksBar from "./Component/NetworksBar"
import Projects from "./Component/Projects"
import Contact from "./Component/Contact"

function App() {

    const [content, setContent] = useState('accueil');

    const handleIconClick = (section) => {
        setContent(section);
    };


  return (
      <div className="h-screen w-full relative flex overflow-hidden">
        <Navbar onIconClick={handleIconClick}/>
          <div className="flex items-center justify-center h-screen w-screen">
              {content === 'accueil' && <Accueil/>}
              {content === 'projets' && <Projects/>}
              {content === 'contact' && <Contact/>}

          </div>
          <NetworksBar/>
      </div>
  );
}

export default App;
