import tweeter from "../assets/App.png"
import franpin from "../assets/logo-nespoli.png"
import oppen from "../assets/oppenheimer1.png"
import virgo from "../assets/virgologo1.png"
import movie from "../assets/MovieBrain/movie2.PNG"
import Card from "./Card"
import CardForPng from "./CardForPng"
import TweeterMore from "./Tweeter"
import Oppenheimer from "./Oppenheimer"
import Nespoli from "./Nespoli"
import Virgo from "./Virgo"
import MovieBrain from "./MovieBrain"

import React, { useState } from 'react';



function Projects(){
    const [selectedProject, setSelectedProject] = useState(null);

    const handleViewMoreClick = (project) => {
        setSelectedProject(project);
    };

    switch (selectedProject) {
        case "MyTweeter":
            return <TweeterMore/>;
        case "MyGame":
            return <Oppenheimer/>
        case "Virgo":
            return <Virgo/>
        case "Nespoli":
            return <Nespoli/>
        case "Moviebrain":
            return <MovieBrain/>
        default:
            return(
                <div className=" grid grid-cols-1 xl:grid-cols-2 max-h-screen overflow-y-auto">
                    <Card title={"MyTweeter"} type={"Projet Scolaire"} text={"MyTweeter, a project developed by our four-member team, is a Twitter replica using PHP, JavaScript, HTML, and CSS. "} picture={tweeter} onViewMoreClick={() => handleViewMoreClick("MyTweeter")}/>

                    <Card title={"MyGame"} type={"Projet Scolaire"} text={"MyGame, a collaborative effort by our team, immerses players in an Oppenheimer-themed adventure."} picture={oppen} onViewMoreClick={() => handleViewMoreClick("MyGame")}/>

                    <CardForPng title={"Virgo Wallet"} type={"Projet Professionnel"} picture={virgo} text={"During my internship at Virgo Wallet, I spearheaded the graphical revamp and implementation of various backend functionalities."} onViewMoreClick={() => handleViewMoreClick("Virgo")}/>

                    <CardForPng title={"Gestion de Stock Franpin"} type={"Projet Professionnel"} picture={franpin} text={"Franpin's Stock Management project! It's the perfect tool to keep an eye on your inventory. Built with HTML,CSS and PHP."} onViewMoreClick={() => handleViewMoreClick("Nespoli")}/>

                    <Card title={"MovieBrain"} type={"Projet Scolaire"} text={"MovieBrain is a solo project in React Native aimed at utilizing the themoviedb API to create an app for recording the movies and series that one has watched."} picture={movie} onViewMoreClick={() => handleViewMoreClick("Moviebrain")}/>

                </div>
            )
    }


}

export default Projects
