function CardForPng({ title, type, text, picture, onViewMoreClick }){
    return(
        <article className="backdrop-blur-md max-w-md md:max-h-80 mt-5 mb-5 ml-5 mr-5 flex items-center justify-center mx-auto rounded-xl shadow-2xl overflow-hidden md:max-w-2xl">
            <div className="md:flex">
                <div className="md:shrink-0 2xl:shrink-0 bg-white flex items-center justify-center">
                    <img className="h-48 w-full scale-50 object-center md:h-48 md:w-48" src={picture}
                         alt="Tweeter"/>
                </div>
                <div className="p-8">
                    <div className="uppercase tracking-wide text-sm text-purple-400	 font-bold">{title}</div>
                    <h4 className="text-purple-200">{type}</h4>
                    <p className="mt-2 text-slate-400">{text}</p>
                    <p  className="text-sky-400 cursor-pointer" onClick={onViewMoreClick}>View More</p>
                </div>
            </div>
        </article>
    )
}
export default CardForPng
